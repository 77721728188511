<template>
  <div class="cart-summary">
    <h3 class="cart-summary__title text-h3">Order Summary</h3>

    <div class="cart-summary__content">
      <div class="cart-summary__table">
        <div class="cart-summary__table-item">
          <span class="cart-summary__table-title">Items for Purchase</span>
          <span class="cart-summary__table-value">{{ cartSize }}</span>
        </div>
        <div class="cart-summary__table-item">
          <span class="cart-summary__table-title">Cart Total</span>
          <span class="cart-summary__table-value">{{ formatPrice(cartData.subtotalWithoutDiscount) }}</span>
        </div>
        <div class="cart-summary__table-item cart-summary__table-item--green" v-if="cartData.totalDiscounts">
          <span class="cart-summary__table-title">Discount</span>
          <span class="cart-summary__table-value">{{ formatPrice(cartData.totalDiscounts) }}</span>
        </div>
        <div class="cart-summary__table-item">
          <span class="cart-summary__table-title">Shipping</span>
          <span class="cart-summary__table-value">{{ cartData.deliveryMode && !isDeliveryModesCalculating
            ? cartData.deliveryMode.price
              ? formatPrice(cartData.deliveryMode.price) : 'Free'
            : 'Pending' }}</span>
        </div>
        <div class="cart-summary__table-item">
          <span class="cart-summary__table-title">Tax</span>
          <span class="cart-summary__table-value">{{ isDeliveryModesCalculating ? 'Pending' : formatPrice(cartData.totalTax) }}</span>
        </div>
      </div>
      <div class="cart-summary__promo">
        <p class="cart-summary__promo-title">Promo Code</p>
        <form
          class="cart-summary__promo-form"
          @submit.prevent="submitVoucher"
        >
          <FormGroup
            v-model.trim="$v.form.promo.$model.value"
            @input="onFieldChange(form.promo)"
            :field="$v.form.promo"
            :isValidationRun="isValidationRun"
            class="cart-summary__promo-input"
          />
          <button
            :class="{'button--disabled': !cartData.customerUid}"
            :disabled="!cartData.customerUid || cartData.appliedVouchers"
            type="submit"
            class="cart-summary__promo-submit button button--outlined"
          >Apply</button>
        </form>
        <p class="form-group__hint cart-summary__promo-hint"
          v-if="form._errors.length"
        >
          <span
            v-for="error in form._errors"
            :key="error.message"
            v-html="voucherErrorsMap[error.message] || error.message"
          ></span>
        </p>
        <p
          v-if="!cartData.customerUid"
          class="form-group__hint form-group__hint--black"
        >
          To apply promo code please sign in or enter your email address on the next step to checkout as guest
        </p>
        <div
          v-if="cartData.totalDiscounts || cartData.appliedPromotions"
          class="cart-summary__discount"
          :class="{'cart-summary__discount--error': cartData.isInvalidPromotionsApplied}"
        >
          <ul class="cart-summary__discount-codes" v-if="cartData.appliedPromotions">
            <li
              class="cart-summary__discount-code-item"
              v-for="voucher in cartData.appliedPromotions"
              :key="voucher.code"
            >
              <template v-if="voucher.isRemovable">
                <div class="cart-summary__discount-code-inner-top">
                  <i
                    v-if="voucher.isRemovable"
                    class="icon-trash-alt cart-summary__discount-code-icon"
                    @click="handleDeleteVoucher(voucher.code)"
                  ></i>
                  <span class="cart-summary__discount-code-text">{{ voucher.code }}</span>
                  <span
                    class="cart-summary__discount-code-total"
                  >- {{ formatPrice(voucher.discount) }}</span>
                </div>
                <div class="cart-summary__discount-code-inner-bottom" v-if="cartData.invalidPromotionsMessage">
                  <span class="cart-summary__discount-code-text">{{ voucher.description }}</span>
                </div>
              </template>
              <div class="cart-summary__discount-code-inner-top" v-else>
                <span class="cart-summary__discount-code-text">{{ voucher.description }}</span>
                <span
                  class="cart-summary__discount-code-total"
                  v-if="voucher.discount"
                >- {{ formatPrice(voucher.discount) }}</span>
              </div>
            </li>
          </ul>
          <div
            v-if="cartData.totalDiscounts"
            class="cart-summary__discount-title-wrap"
            :class="{'cart-summary__discount-title-wrap--margin': cartData.appliedPromotions}"
          >
            <span class="cart-summary__discount-title">Total Discount</span>
            <span class="cart-summary__discount-value">- {{ formatPrice(cartData.totalDiscounts) }}</span>
          </div>
        </div>
      </div>

      <div class="cart-summary__total">
        <span class="span cart-summary__total-title">Total Order Amount</span>
        <span class="span cart-summary__total-price">{{ isDeliveryModesCalculating ? 'Pending' : formatPrice(cartData.totalPrice) }}</span>
      </div>

      <div class="cart-summary__buttons-wrap">
        <slot name='flow-control'></slot>
      </div>
    </div>
    <loader v-if="voucherActionProcessing"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { required } from 'vuelidate/lib/validators'
import FormatMixin from '@/mixins/FormatMixin'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import FormGroup from '@/components/FormGroup'

export default {
  name: 'CartSummary',
  components: {
    FormGroup
  },
  mixins: [FormatMixin, FormValidationMixin],
  props: ['cartData'],
  watch: {
    'form.promo.value' () {
      if (this.form._errors?.length) this.clearFormServerErrors()
    },
    'cartData.appliedVouchers' (val) {
      this.form.promo.disabled = !!val
    }
  },
  computed: {
    ...mapGetters('cart', ['cartSize']),
    ...mapState('cart', ['isDeliveryModesCalculating']),
    isModelValid () {
      return !this.$v.$invalid
    }
  },
  methods: {
    ...mapActions('cart', ['setVoucher', 'deleteVoucher']),
    submitVoucher () {
      if (!this.voucherActionProcessing) {
        this.withPresendRoutine(() => {
          this.voucherActionProcessing = true
          this.sendForm(this.setVoucher, { voucherId: this.form.promo.value })
            .then(() => {
              this.form.promo.value = ''
            }).catch(() => {
              this.isValidationRun = true
            }).finally(() => {
              this.voucherActionProcessing = false
            })
        })
      }
    },
    async handleDeleteVoucher (voucher) {
      if (!this.voucherActionProcessing) {
        this.voucherActionProcessing = true
        await this.deleteVoucher(voucher)
        this.voucherActionProcessing = false
      }
    }
  },
  validations () {
    return {
      form: {
        promo: {
          value: {
            required,
            server: this.server
          }
        }
      }
    }
  },
  data () {
    return {
      isValidationRun: false,
      form: {
        promo: {
          type: 'text',
          variant: 'shadow',
          title: 'voucherId',
          value: '',
          disabled: !this.cartData.customerUid || this.cartData.appliedVouchers,
          errors: {
            required: 'Please enter a Promo Code'
          }
        },
        _errors: []
      },
      voucherActionProcessing: false,
      voucherErrorsMap: {
        'coupon.invalid.code.provided': 'Invalid code',
        'coupon.already.exists.cart': 'The code has already been activated',
        'coupon.already.redeemed': 'The code has already been redeemed',
        'coupon.not.active.expired': 'Promo code is expired'
      }
    }
  }
}
</script>
