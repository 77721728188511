<template>
  <div class="cart-guest-checkout-interruption">
    <div class="cart-guest-checkout-interruption__col cart-guest-checkout-interruption__col--left">
      <span class="cart-guest-checkout-interruption__title">Already have a Hubbell.com account?</span>
      <span class="cart-guest-checkout-interruption__subtitle">Returning customers and employees can sign in below; new customers can click Sign Up to register a new account</span>
      <button class="cart-guest-checkout-interruption__signin button button--primary" @click="onLoginClick">Sign In</button>
      <button class="cart-guest-checkout-interruption__signup button" @click="onLoginClick">Sign Up</button>
    </div>

    <div class="cart-guest-checkout-interruption__col">
      <span class="cart-guest-checkout-interruption__title">Or checkout as guest</span>
      <span class="cart-guest-checkout-interruption__subtitle">Enter email address for order and shipment updates</span>
      <form class="cart-guest-checkout-interruption__form" @submit.prevent="onFormSubmit">
        <FormGroup
          v-model.trim="$v.form.email.$model.value"
          @input="onFieldChange(form.email)"
          :field="$v.form.email"
          :isValidationRun="isValidationRun"
        />
        <FormCheckbox
          class="cart-summary__terms"
          v-model="subscriptionCheckbox.value"
          :field="subscriptionCheckbox"
        />
        <button
          type="submit"
          :disabled="isLoading"
          class="cart-guest-checkout-interruption__submit button button--primary"
        >Continue as Guest</button>
      </form>

      <CountrySwitcher />
    </div>
    <loader v-if="!isInitialized" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import { required, email } from 'vuelidate/lib/validators'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import FormGroup from '@/components/FormGroup'
import FormCheckbox from '@/components/FormCheckbox'
import CountrySwitcher from '@/components/CountrySwitcher'

export default {
  name: 'CartGuestCheckoutInterruption',
  components: {
    FormGroup,
    FormCheckbox,
    CountrySwitcher
  },
  mixins: [FormValidationMixin, LoaderMixin],
  computed: {
    ...mapState('cart', ['cartData', 'checkoutStatuses']),
    ...mapState('user', ['emailSubscriptionsData']),
    ...mapGetters('app', ['isUSSite']),
    ...mapGetters('user', ['isUserHasEmailSubscription'])
  },
  methods: {
    ...mapActions('app', ['doLogin', 'doSignup']),
    ...mapActions('cart', ['setGuestEmail']),
    ...mapActions('user', ['setEmailSubscriptionsData', 'updateEmailSubscriptionsData']),
    onLoginClick () {
      this.doLogin({ customPath: '/cart/shipping' })
    },
    handleUpdateEmailSubscriptionsData () {
      if (!this.isUserHasEmailSubscription && this.subscriptionCheckbox.value) {
        this.updateEmailSubscriptionsData({
          pageName: 'Cart | Hubbell Store',
          pageUri: `${window.location.origin}/cart/guest-login`,
          newProductAnnouncements: true,
          salesAndDiscounts: true
        })
      }
    },
    onFormSubmit () {
      if (this.cartData.customerUid && this.form.email.value === this.cartData.customerUid) {
        this.handleUpdateEmailSubscriptionsData()
        this.$emit('emailFormSubmitted')
      } else {
        this.withPresendRoutine(() => {
          this.withLoader(() => {
            return this.sendForm(this.setGuestEmail, encodeURIComponent(this.form.email.value))
              .then(() => {
                return this.isUSSite ? this.setEmailSubscriptionsData() : null
              }).then(() => {
                this.handleUpdateEmailSubscriptionsData()
                this.$emit('emailFormSubmitted')
              }).catch(() => {
                this.isValidationRun = true
              })
          })
        })
      }
    }
  },
  validations () {
    return {
      form: {
        email: {
          value: {
            required,
            email,
            server: this.server
          }
        }
      }
    }
  },
  async created () {
    this.pushDataToForm({ email: this.cartData.customerUid })
    if (this.cartData.customerUid) await this.setEmailSubscriptionsData()
    this.isInitialized = true
  },
  data () {
    return {
      isValidationRun: false,
      isInitialized: false,
      form: {
        email: {
          type: 'email',
          label: 'EMAIL ADDRESS: *',
          title: 'email',
          value: '',
          errors: {
            required: 'Please enter a Email Address',
            email: 'Please enter a valid Email Address'
          }
        }
      },
      subscriptionCheckbox: {
        title: 'subscriptionCheckbox',
        type: 'checkbox',
        label: 'I want to sign-up to receive promotions & discounts from the Hubbell Store',
        variant: 'shadow',
        value: !!this.isUSSite
      }
    }
  }
}
</script>
