<template>
  <div>
    <div class="cart" v-if="cartSize && currentStepComponent">
      <CartNavigation class="cart__navigation" />
      <div class="container">
        <h2 class="cart__title">{{ currentStep.stepTitle }}</h2>
        <component v-if="isInitialized" :is="currentStepComponent" />
      </div>
      <loader v-if="!isInitialized" />
    </div>
    <div class="cart__empty-block" v-else>
      <div class="cart__empty">
        <h1 class="text-h1">Your cart is empty.</h1>
        <p v-if="isGuest" class="cart__empty-login-copy">
          <a
            @click.prevent="doLogin"
            href="/login"
            class="link"
          >Sign In</a>
          to view your cart (or
          <a
            @click.prevent="doSignup"
            href="/signup"
            class="link"
          >Create an Account</a>)
        </p>
        <router-link
          to="/search"
          class="button button--primary-black"
          v-html="'Shop Full Catalog'"
        ></router-link>
      </div>

      <CartSaveForLater v-if="!isGuest" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import CartEditStep from '@/components/cart/edit-step/CartEditStep'
import CartInterruptionStep from '@/components/cart/interruption-step/CartInterruptionStep'
import CartShippingStep from '@/components/cart/shipping-step/CartShippingStep'
import CartBillingStep from '@/components/cart/billing-step/CartBillingStep'
import CartReviewStep from '@/components/cart/review-step/CartReviewStep'
import CartNavigation from '@/components/cart/common/CartNavigation'
import CartSaveForLater from '@/components/cart/common/CartSaveForLater'

export default {
  name: 'Cart',
  components: {
    CartEditStep,
    CartInterruptionStep,
    CartShippingStep,
    CartBillingStep,
    CartReviewStep,
    CartNavigation,
    CartSaveForLater
  },
  mixins: [PageMixin, LoaderMixin],
  computed: {
    ...mapGetters('cartFlow', ['currentStep']),
    ...mapState('cart', ['cartData', 'isCartDataUpdateOnPageLoadRequired', 'checkoutStatuses', 'orderStatuses']),
    ...mapGetters('cart', ['cartSize', 'isCartContainsUnavailableProduct']),
    ...mapGetters('user', ['isGuest']),
    currentStepComponent () {
      const stepComponents = {
        'review-cart': CartEditStep,
        'guest-login': CartInterruptionStep,
        shipping: CartShippingStep,
        billing: CartBillingStep,
        'review-order': CartReviewStep
      }
      return stepComponents[this.currentStep.value]
    }
  },
  methods: {
    ...mapActions('cartFlow', ['resetCartFlow']),
    ...mapActions('cart', ['setCartData', 'getCheckoutAndOrderStatuses']),
    ...mapActions('app', ['doLogin', 'doSignup'])
  },
  async created () {
    this.withLoader(async () => {
      if ((!this.currentStep && this.cartSize) || this.isCartContainsUnavailableProduct) this.resetCartFlow()
      const shouldLoadStatuses = !Object.values(this.checkoutStatuses).length || !Object.values(this.orderStatuses).length
      if (shouldLoadStatuses) await this.getCheckoutAndOrderStatuses()
      if (this.isCartDataUpdateOnPageLoadRequired) await this.setCartData()
      this.isInitialized = true
    })
  },
  data () {
    return {
      isInitialized: false
    }
  }
}
</script>
