<template>
  <div v-if="saveForLaterData && saveForLaterData.entries && saveForLaterData.entries.length" class="cart-save-for-later">
    <h2 class="cart__title">Saved for Later</h2>
    <CartProductsList
      :isListEditable="true"
      :productsQty="saveForLaterData.entries.length"
    >
      <template v-slot:cart-entry>
        <CartProduct
          v-for="cartEntry in saveForLaterData.entries"
          :key="cartEntry.sku"
          @quantityUpdated="handleProductUpdate"
          @entryDeleteRequested="openDeleteSingleProductModal(cartEntry)"
          @navigateToProductDetails="setProductAnalyticsData({ referencePage: 'Cart' })"
          :cartEntry="cartEntry"
          :isAddToCartVisible="false"
        >
          <template v-slot:actions>
            <button
              type="button"
              @click="moveSingleProductToCart(cartEntry)"
              class="cart-product__actions-button  button button--primary button--cart"
            >
              <i class="icon-shopping-cart"></i>
              Add to Cart
            </button>
          </template>
        </CartProduct>
      </template>
      <template v-slot:loader>
        <loader v-if="isLoading || isProcessing"/>
      </template>
    </CartProductsList>

    <div class="cart__bottom-actions">
      <button class="cart__bottom-action button button--underlined" @click="moveAllProductsToCart">Add All to Cart</button>
      <button class="cart__bottom-action button button--underlined" @click="showDeleteAllProductsModal = true">Remove All</button>
    </div>

    <ModalSimple
      @confirm="handleDeleteSingleProduct"
      @reject="closeDeleteSingleProductModal"
      @close="closeDeleteSingleProductModal"
      :showModal="showDeleteSingleProductModal"
      :title="deleteSingleProductCandidate ? `Confirm delete: ${deleteSingleProductCandidate.product.title}` : ''"
    />

    <ModalSimple
      @confirm="handleDeleteAllProducts"
      @reject="showDeleteAllProductsModal = false"
      @close="showDeleteAllProductsModal = false"
      :showModal="showDeleteAllProductsModal"
      title="Confirm delete: all Saved for Later products"
    />
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import LoaderMixin from '@/mixins/LoaderMixin'
import CartProductsList from '@/components/cart/common/CartProductsList'
import CartProduct from '@/components/cart/common/CartProduct'
import ModalSimple from '@/components/modal/ModalSimple'

export default {
  name: 'CartSaveForLater',
  components: {
    CartProductsList,
    CartProduct,
    ModalSimple
  },
  mixins: [LoaderMixin],
  watch: {
    saveForLaterData: {
      immediate: true,
      handler (val) {
        if (val && this.isProductsPriceRecalculationRequired) this.recalculatePoductsPrice()
      }
    }
  },
  computed: {
    ...mapState('cart', ['cartData']),
    ...mapState('saveForLater', ['saveForLaterData', 'isProcessing', 'isProductsPriceRecalculationRequired'])
  },
  methods: {
    ...mapActions('saveForLater', [
      'setSaveForLaterData',
      'updateProductInSaveForLater',
      'deleteProductsFromSaveForLater',
      'moveProductsFromSaveForLaterToCart',
      'recalculatePoductsPrice'
    ]),
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    moveSingleProductToCart (cartEntry) {
      this.withLoader(() => this.moveProductsFromSaveForLaterToCart([cartEntry]))
    },
    moveAllProductsToCart () {
      this.withLoader(() => this.moveProductsFromSaveForLaterToCart(this.saveForLaterData.entries))
    },
    handleDeleteSingleProduct () {
      this.withLoader(async () => {
        this.showDeleteSingleProductModal = false
        await this.deleteProductsFromSaveForLater([this.deleteSingleProductCandidate.product.sku])
        this.deleteSingleProductCandidate = null
      })
    },
    handleDeleteAllProducts () {
      this.withLoader(async () => {
        this.showDeleteAllProductsModal = false
        return this.deleteProductsFromSaveForLater(this.saveForLaterData.entries.map(entry => entry.product.sku))
      })
    },
    handleProductUpdate (cartEntry) {
      this.withLoader(() => this.updateProductInSaveForLater(cartEntry))
    },
    openDeleteSingleProductModal (product) {
      this.deleteSingleProductCandidate = product
      this.showDeleteSingleProductModal = true
    },
    closeDeleteSingleProductModal () {
      this.showDeleteSingleProductModal = false
      this.deleteSingleProductCandidate = null
    }
  },
  created () {
    if (!this.saveForLaterData) this.setSaveForLaterData()
  },
  data () {
    return {
      showDeleteSingleProductModal: false,
      deleteSingleProductCandidate: null,
      showDeleteAllProductsModal: false
    }
  }
}
</script>
