<template>
  <div class="form-checkbox">
    <div
      class="radio"
      :class="{'radio--checked': value === field.value, 'radio--disabled': disabled}"
    >
      <label :for="field.label" class="radio__label">
        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" class="radio-icon">
          <path d="M12,22a10,10 0 0 1 -10,-10a10,10 0 0 1 10,-10a10,10 0 0 1 10,10a10,10 0 0 1 -10,10m0,-22a12,12 0 0 0 -12,12a12,12 0 0 0 12,12a12,12 0 0 0 12,-12a12,12 0 0 0 -12,-12" class="radio-icon__border"></path>
          <path d="M12,6a6,6 0 0 0 -6,6a6,6 0 0 0 6,6a6,6 0 0 0 6,-6a6,6 0 0 0 -6,-6" class="radio-icon__dot"></path>
        </svg>

        <input
          @click.prevent="$emit('change', field)"
          :id="field.label"
          :checked="value === field.value"
          :disabled="disabled"
          type="radio"
          class="radio__input"
        />

        <slot v-if="$slots['label-slot']" name="label-slot"></slot>
        <span v-else class="radio__text" v-html="field.label"></span>
      </label>
    </div>

    <template v-if="isValidationRun && errors">
      <span
        v-for="(error, idx) in errors"
        :key="idx"
        v-html="error"
        class="form-group__hint"
      />
    </template>
  </div>
</template>

<script>
import FormControlMixin from '@/mixins/FormControlMixin'

export default {
  name: 'FormRadio',
  mixins: [FormControlMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
