import { mapActions, mapGetters } from 'vuex'

import PageTitleMixin from '@/mixins/PageTitleMixin'

export default {
  mixins: [PageTitleMixin],
  computed: {
    ...mapGetters('cartFlow', ['currentStep']),
    canStepForward () {
      return this.currentStep.next.step?.isAccessible
    },
    canStepBack () {
      return true
    },
    pageTitle () {
      return this.currentStep?.pageTitle
    }
  },
  methods: {
    ...mapActions('cartFlow', ['resetCartFlow', 'incrementStep', 'decrementStep']),
    onBackStepClick () {
      this.decrementStep()
    },
    onNextStepClick () {
      this.incrementStep()
    }
  },
  created () {
    if (!this.currentStep.isAccessible) {
      this.resetCartFlow()
    }
    this.setPageTitle(this.pageTitle)
  }
}
