<template>
  <div class="cart__content">
    <div class="cart__step">
      <div class="cart-billing">
        <div v-if="false" class="cart-billing__section">
          <div class="cart-heading">
            <h2 class="cart-heading__title">Payment Method</h2>
          </div>

          <div class="cart-billing__section-content">
            <FormRadio
              v-for="paymentMethod in paymentMethods"
              :key="paymentMethod.id"
              @change="selectPaymentMethod"
              :value="selectedPaymentMethod.value"
              :field="paymentMethod"
            />
          </div>
        </div>

        <div class="cart-billing__section">
          <div class="cart-heading">
            <h2 class="cart-heading__title">Billing Information</h2>
          </div>
          <div class="cart-billing__section-content--billing-info">
            <component
              :is="paymentComponent"
              v-if="showPaymentComponent"
              @processComplete="onPaymentProcessComplete"
              @processCancelled="onPaymentProcessCancel"
            />
            <div class="cart-billing-review" v-else-if="cartData.paymentDetails">
              <div class="cart-billing-review__data">
                <AddressItem class="cart-billing-review__address" :data="cartData.paymentDetails.billingAddress" />
                <PaymentCard class="art-billing-review__payment" :data="cartData.paymentDetails"/>
              </div>
              <button class="cart-billing-review__action button button--primary" @click="onEditPaymentClick">Edit Billing Information</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart__summary">
      <CartSummary
        :cartData="cartData"
      >
        <template v-slot:flow-control>
          <button
            v-if="currentStep.previous.step"
            @click="onBackStepClick"
            v-html="currentStep.previous.actionText"
            :disabled="!canStepBack"
            class="cart-summary__button cart-summary__button--back button button--outlined"
          ></button>
          <button
            v-if="currentStep.next.step"
            @click="onNextStepClick"
            v-html="currentStep.next.actionText"
            :disabled="!canStepForward"
            class="cart-summary__button cart-summary__button--next button button--primary"
          ></button>
        </template>
      </CartSummary>
    </div>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'

import CartStepMixin from '@/mixins/CartStepMixin'
import CartSummary from '@/components/cart/common/CartSummary'
import FormRadio from '@/components/FormRadio'
import CartBillingCreditCard from '@/components/cart/billing-step/CartBillingCreditCard'
import AddressItem from '@/components/AddressItem'
import PaymentCard from '@/components/PaymentCard'

export default {
  name: 'CartBillingStep',
  components: {
    CartSummary,
    FormRadio,
    CartBillingCreditCard,
    AddressItem,
    PaymentCard
  },
  mixins: [CartStepMixin],
  computed: {
    ...mapState('cart', ['cartData', 'checkoutStatuses', 'orderStatuses']),
    paymentComponent () {
      switch (this.selectedPaymentMethod.value) {
        case 'creditCard':
          return CartBillingCreditCard
        default:
          return null
      }
    }
  },
  methods: {
    ...mapActions('cart', ['setCartData', 'sendCartStepsHubspotAnalytics']),
    ...mapActions('analytics', ['populateCheckoutStepsData']),
    selectPaymentMethod (val) {
      this.selectedPaymentMethod = val
    },
    async onPaymentProcessComplete () {
      await this.setCartData()
      this.showPaymentComponent = false

      this.onNextStepClick()
    },
    onPaymentProcessCancel () {
      if (this.cartData.paymentDetails) {
        this.showPaymentComponent = false
      } else {
        this.decrementStep()
      }
    },
    onEditPaymentClick () {
      this.showPaymentComponent = true
    }
  },
  created () {
    this.selectPaymentMethod(this.paymentMethods[0])
    this.showPaymentComponent = !this.cartData.paymentDetails || this.$route.query.showForm

    this.populateCheckoutStepsData({
      step: '3',
      option: ''
    })
    this.sendCartStepsHubspotAnalytics({
      orderStage: this.orderStatuses.checkoutPending,
      checkoutStage: this.checkoutStatuses.billing
    })
  },
  data () {
    return {
      showPaymentComponent: false,
      selectedPaymentMethod: null,
      paymentMethods: [
        {
          type: 'radio',
          label: 'Credit Card',
          id: 1,
          value: 'creditCard'
        }
      ]
    }
  }
}
</script>
