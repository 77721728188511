<template>
  <div class="cart__content">
    <div class="cart__step">
      <CartShipping
        :addressData='shippingAddressData'
       />
    </div>
    <div class="cart__summary">
      <CartSummary
        :cartData="cartData"
      >
        <template v-slot:flow-control>
          <button
            v-if="currentStep.previous.step"
            @click="onBackStepClick"
            v-html="currentStep.previous.actionText"
            :disabled="!canStepBack"
            class="cart-summary__button cart-summary__button--back button button--outlined"
          ></button>
          <button
            v-if="currentStep.next.step"
            @click="handleNextStepClick"
            v-html="currentStep.next.actionText"
            :disabled="!canStepForward || isDeliveryModesCalculating"
            class="cart-summary__button cart-summary__button--next button button--primary"
          ></button>
        </template>
      </CartSummary>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CartStepMixin from '@/mixins/CartStepMixin'
import CartSummary from '@/components/cart/common/CartSummary'
import CartShipping from '@/components/cart/shipping-step/CartShipping'

export default {
  name: 'CartShippingStep',
  components: {
    CartShipping,
    CartSummary
  },
  mixins: [CartStepMixin],
  computed: {
    ...mapState('cart', ['cartData', 'isDeliveryModesCalculating', 'checkoutStatuses', 'orderStatuses']),
    ...mapState('user', ['shippingAddressData'])
  },
  methods: {
    ...mapActions('cart', ['sendCartStepsHubspotAnalytics']),
    ...mapActions('analytics', ['populateCheckoutStepsData', 'populateBeginCheckoutAnalytics', 'populateShippingCheckoutAnalytics']),
    handleNextStepClick () {
      this.populateShippingCheckoutAnalytics()
      this.onNextStepClick()
    }
  },
  mounted () {
    this.populateCheckoutStepsData({
      step: '2',
      option: this.cartData?.deliveryMode?.title
    })
    this.populateBeginCheckoutAnalytics()
    this.sendCartStepsHubspotAnalytics({
      orderStage: this.orderStatuses.checkoutPending,
      checkoutStage: this.checkoutStatuses.shipping
    })
  }
}
</script>
