<template>
  <div class="cart__content" v-if="isInitialized">
    <div class="cart__step cart-review-step">
      <HintBar
        v-if="showHint"
        :hintData="{ message: hintErrorMessage }"
        class="hint-bar--orange"
      />
      <CartProductsList
        :isListEditable="false"
        :productsQty="cartSize"
      >
        <template v-slot:cart-entry>
          <CartProduct
            v-for="cartEntry in cartData.entries"
            :key="cartEntry.sku"
            :cartEntry="cartEntry"
            :isEntryEditable="false"
            :isAddToCartVisible="false"
            @navigateToProductDetails="setProductAnalyticsData({ referencePage: 'Order Review' })"
          />
        </template>
      </CartProductsList>

      <div v-if="cartData.paymentDetails" class="billing-info-section">
        <div class="billing-info-section__heading">
          <h2 class="billing-info-section__title">Billing Information</h2>
        </div>
        <div class="cart-billing-review billing-info-section__main">
          <div class="cart-billing-review__data">
            <AddressItem class="cart-billing-review__address" :data="cartData.paymentDetails.billingAddress" />
            <PaymentCard class="art-billing-review__payment" :data="cartData.paymentDetails"/>
          </div>
          <button class="cart-billing-review__action button button--primary" @click="onEditPaymentClick">Edit Billing Information</button>
        </div>
      </div>
    </div>
    <div class="cart__summary">
      <CartSummary
        :cartData="cartData"
      >
        <template v-slot:flow-control>
        <div class="cart-summary__buttons-wrap-inner">
          <FormCheckbox
            v-if="isEmployee"
            v-model="termsAndConditionsEmployee.value"
            :field="termsAndConditionsEmployee"
            class="cart-summary__terms"
          >
            <template v-slot:icon>
              <i class="icon-question-circle" v-tooltip="'Employees can reference employee purchase terms by consulting the Hubbell Policy Portal via Harvey'"  />
            </template>
          </FormCheckbox>
          <FormCheckbox
            v-model="termsAndConditions.value"
            :field="termsAndConditions"
            class="cart-summary__terms"
          />
          <FormCheckbox
            v-if="!isUserHasEmailSubscription"
            class="cart-summary__terms"
            v-model="subscriptionCheckbox.value"
            :field="subscriptionCheckbox"
          />
          <div class="cart-summary__buttons-wrap">
            <button
              v-if="currentStep.previous"
              @click="onBackStepClick"
              v-html="currentStep.previous.actionText"
              :disabled="!canStepBack"
              class="cart-summary__button cart-summary__button--back button button--outlined"
            ></button>
            <button
              @click="onNextStepClick"
              v-html="currentStep.next.actionText"
              :disabled="!canStepForward"
              class="cart-summary__button cart-summary__button--next button button--primary"
            ></button>
          </div>
        </div>
        </template>
      </CartSummary>
    </div>
    <loader v-if="isLoading"/>
    <ModalOrderConfirmation
      @close="onModalConfirmationClose"
      :orderConfirmationData="placedOrderData"
      :showModal="showOrderConfirmationModal"
    />
  </div>
  <loader v-else />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import LoaderMixin from '@/mixins/LoaderMixin'
import CartStepMixin from '@/mixins/CartStepMixin'
import CartProductsList from '@/components/cart/common/CartProductsList'
import CartSummary from '@/components/cart/common/CartSummary'
import CartProduct from '@/components/cart/common/CartProduct'
import ModalOrderConfirmation from '@/components/modal/ModalOrderConfirmation'
import FormCheckbox from '@/components/FormCheckbox'
import HintBar from '@/components/HintBar'
import AddressItem from '@/components/AddressItem'
import PaymentCard from '@/components/PaymentCard'

const INSUFICIENT_STOCK_ERROR = 'InsufficientStockError'
const PLACE_ORDER_ERROR = 'PlacingOrderRestrictException'
const HINT_ERROR_MESSAGES = {
  [INSUFICIENT_STOCK_ERROR]: 'An item in your cart is no longer available; please remove from cart to continue',
  [PLACE_ORDER_ERROR]: 'We’re sorry but we cannot accept orders at this time due to technical maintenance. We are working to resolve this temporary pause as quickly as possible.'
}

export default {
  name: 'CartReviewStep',
  components: {
    CartProductsList,
    CartSummary,
    CartProduct,
    ModalOrderConfirmation,
    FormCheckbox,
    HintBar,
    AddressItem,
    PaymentCard
  },
  mixins: [LoaderMixin, CartStepMixin],
  computed: {
    ...mapState('cart', ['placedOrderData', 'cartData', 'orderStatuses', 'checkoutStatuses']),
    ...mapState('user', ['userData', 'emailSubscriptionsData']),
    ...mapGetters('user', ['isEmployee', 'isGuest', 'isUserHasEmailSubscription']),
    ...mapGetters('cart', ['cartSize']),
    ...mapGetters('app', ['isUSSite']),
    canStepForward () {
      return this.isEmployee
        ? this.termsAndConditions.value && this.termsAndConditionsEmployee.value
        : this.termsAndConditions.value
    }
  },
  methods: {
    ...mapActions('analytics', [
      'populateOrderAnalyticsData',
      'populateCheckoutStepsData',
      'populatePurchaseCheckoutAnalytics',
      'populatePaymentInformationCheckoutAnalytics'
    ]),
    ...mapActions('cart', ['placeOrder', 'setCartData', 'resetCart', 'sendCartStepsHubspotAnalytics']),
    ...mapActions('user', ['setEmailSubscriptionsData', 'updateEmailSubscriptionsData']),
    ...mapActions('fullstory', { fullstoryOrderComplete: 'orderComplete' }),
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    async onModalConfirmationClose (path) {
      await this.$router.push(path)
      this.resetCart()
    },
    async onNextStepClick () {
      this.isLoading = true
      this.showHint = false

      if (!this.isUserHasEmailSubscription && this.subscriptionCheckbox.value) {
        try {
          await this.updateEmailSubscriptionsData({
            pageName: 'Checkout | Hubbell Store',
            pageUri: `${window.location.origin}/cart/review-order`,
            newProductAnnouncements: true,
            salesAndDiscounts: true
          })
        } catch (e) {
          console.error(e)
        }
      }

      this.placeOrder().then(() => {
        this.populateOrderAnalyticsData()
        this.fullstoryOrderComplete()

        this.populateCheckoutStepsData({
          step: '5',
          option: ''
        })
        this.sendCartStepsHubspotAnalytics({
          orderStage: this.orderStatuses.checkoutPending,
          checkoutStage: this.checkoutStatuses.orderConfirmation
        })
        this.populatePurchaseCheckoutAnalytics()

        this.showOrderConfirmationModal = true
      }).catch(error => {
        if (error?.response?.data) {
          const errorMessage = HINT_ERROR_MESSAGES[error.response.data.type]
          if (errorMessage) {
            this.hintErrorMessage = errorMessage
            this.showHint = true
          }
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    onEditPaymentClick () {
      this.$router.push({
        path: '/cart/billing',
        query: {
          showForm: true
        }
      })
    }
  },
  async created () {
    if (this.isUSSite && this.cartData.customerUid) await this.setEmailSubscriptionsData()
    this.isInitialized = true

    this.populateCheckoutStepsData({
      step: '4',
      option: this.cartData?.deliveryMode?.title
    })
    this.populatePaymentInformationCheckoutAnalytics()
    this.sendCartStepsHubspotAnalytics({
      orderStage: this.orderStatuses.checkoutPending,
      checkoutStage: this.checkoutStatuses.reviewOrder
    })
  },
  data () {
    return {
      showOrderConfirmationModal: false,
      isInitialized: false,
      termsAndConditions: {
        type: 'checkbox',
        label: 'I accept all <a href="https://hubbell.com/hubbell/en/web-terms" target="_blank">terms and conditions</a>',
        variant: 'shadow',
        value: false
      },
      termsAndConditionsEmployee: {
        type: 'checkbox',
        label: 'I have reviewed and I accept the Employee Purchase of Company Products Policy <a href="https://user-ikayykk.cld.bz/Policy-Portal-Hubbell/233/" target="_blank">(HR-09)</a>',
        variant: 'shadow',
        value: false
      },
      subscriptionCheckbox: {
        type: 'checkbox',
        label: 'I want to sign-up to receive promotions & discounts from the Hubbell Store',
        variant: 'shadow',
        value: !!this.isUSSite
      },
      showHint: false,
      hintErrorMessage: null
    }
  }
}
</script>
