<template>
  <div class="cart-billing__card-frame" v-if="requestFormParams">
    <form ref="billingIFrameForm" :action="requestFormParams.url" method="post" target="payment_iframe">
      <input
        v-for='field in requestFormParams.params'
        :key='field[0]'
        :name="field[0].toUpperCase()"
        :value='field[1]'
        type="hidden"
      >
    </form>
    <iframe
      ref="billingIFrame"
      name="payment_iframe"
      class="cart-billing__card-frame-box"
      width="100%"
      height="1000px"
      frameBorder="0"
      loading="lazy"
    ></iframe>
    <loader v-if="isLoading"/>
  </div>
  <loader v-else />
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CartBillingCreditCard',
  props: ['cartData'],
  computed: {
    ...mapState('cart', ['paymentProviderData']),
    requestFormParams () {
      const { paymentProviderUrl, ...rest } = this.paymentProviderData || {}
      return this.paymentProviderData ? {
        url: paymentProviderUrl,
        params: { ...Object.entries({ ...rest, JS_FILE_NAME: 'HUBBELL_CUSTOM_JS', CSS_FILE_NAME: 'hubbell-custom-css' }) }
      } : null
    }
  },
  methods: {
    ...mapActions('cart', ['setPaymentProviderData']),
    processPaymentResponse (event) {
      if (
        this.$refs.billingIFrame &&
        this.$refs.billingIFrame.contentWindow === event.source &&
        event.origin === window.location.origin
      ) {
        const paymentResponse = JSON.parse(event.data)
        if (paymentResponse?.status === 'success') {
          this.$emit('processComplete')
        } else if (paymentResponse?.status === 'cancelled') {
          this.$emit('processCancelled')
        }
      }
    }
  },
  async created () {
    window.addEventListener('message', this.processPaymentResponse, false)
    this.isLoading = true
    await this.setPaymentProviderData()
    this.$refs.billingIFrameForm.submit()
    this.$refs.billingIFrame.onload = () => {
      this.isLoading = false
    }
  },
  beforeDestroy () {
    window.removeEventListener('message', this.processPaymentResponse)
  },
  data () {
    return {
      isLoading: false
    }
  }
}
</script>
