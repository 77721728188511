<template>
  <Modal
    :showModal="showModal"
    @close="$emit('close')"
    class="modal-cart-account-registration text-center"
    :class="{ 'modal-cart-account-registration--two-col': isGuest }"
  >
    <template v-slot:body>
      <div class="modal-cart-account-registration__row">
        <div
          class="modal-cart-account-registration__col"
          :class="{ 'modal-cart-account-registration__col--single': !isGuest }"
        >
          <h3 class="text-h3">Thank you for your order!</h3>
          <p class="modal-cart-account-registration__message" v-if="orderConfirmationData">
            Your order number is
            <router-link
              v-if="!isGuest"
              :to="orderConfirmationData.link"
              @click.native="$emit('close', orderConfirmationData.link)"
              class="text-underline"
            >{{orderConfirmationData.id}}</router-link>
            <span v-else>{{orderConfirmationData.id}}</span>. A copy of your order details has been sent to {{orderConfirmationData.customerUid}}
          </p>
          <div class="modal__actions">
            <button
              type="button"
              class="button button--primary"
              @click="$emit('close', '/')"
            >
              Continue shopping
            </button>
          </div>
        </div>
        <div v-if="isGuest" class="modal-cart-account-registration__col modal-cart-account-registration__col--right">
          <h3 class="text-h3">Create Hubbell Account</h3>
          <p class="modal-cart-account-registration__message" v-if="orderConfirmationData">
            Create a Hubbell account to view your order history and tracking, create favorites, manage your address book
            for faster checkout, and more
          </p>
          <div class="modal__actions">
            <button
              type="button"
              class="button button--primary"
              @click="doSignup"
            >
              Create account
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Modal from '@/components/modal/Modal'

export default {
  name: 'ModalOrderConfirmation',
  components: {
    Modal
  },
  props: ['orderConfirmationData', 'showModal'],
  computed: {
    ...mapGetters('user', ['isGuest'])
  },
  methods: {
    ...mapActions('app', ['doSignup'])
  }
}
</script>
