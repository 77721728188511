<template>
  <div class="cart__content">
    <div class="cart__step">
      <HintBar
        v-if="isCartContainsUnavailableProduct"
        :hintData="hintInsufficientStockError"
        class="hint-bar--orange"
      />
      <template v-if="potentialPromotions">
        <HintBar
          v-for="promoBanner in potentialPromotions"
          :key="promoBanner.code"
          :hintData="promoBanner"
        />
      </template>
      <CartProductsList
        :isListEditable="true"
        :productsQty="cartSize"
      >
        <template v-slot:cart-entry>
          <CartProduct
            v-for="cartEntry in cartData.entries"
            :key="cartEntry.sku"
            @quantityUpdated="onCartEntryUpdate"
            @entryDeleteRequested="openDeleteCartEntryModal"
            @navigateToProductDetails="setProductAnalyticsData({ referencePage: 'Cart' })"
            :cartEntry="cartEntry"
            :isAddToCartVisible="false"
            :isImageClickable="false"
          >
            <template v-slot:actions>
              <button
                v-tooltip="{
                  content:  isGuest ? 'Create account or login to save item for later' : '',
                  trigger: 'hover',
                  autoHide: true
                }"
                @click="isGuest ? doLogin() : onSaveForLaterClick(cartEntry)"
                class="button button--underlined"
              >Save for Later</button>
            </template>
          </CartProduct>
        </template>
        <template v-slot:loader>
          <loader v-if="isLoading"/>
        </template>
      </CartProductsList>
      <div class="cart__bottom-actions">
        <button class="cart__bottom-action button button--underlined" @click="openDeleteCartModal">Remove All</button>
      </div>

      <CartSaveForLater v-if="!isGuest" />
    </div>
    <div class="cart__summary">
      <CartSummary
        :cartData="cartData"
      >
        <template v-slot:flow-control>
          <button
            v-if="currentStep.previous.step"
            @click="onBackStepClick"
            v-html="currentStep.previous.actionText"
            :disabled="!canStepBack"
            class="cart-summary__button cart-summary__button--back button button--outlined"
          ></button>
          <button
            v-if="true"
            @click="onNextStepClick"
            v-html="currentStep.next.actionText"
            :disabled="!canStepForward"
            class="cart-summary__button cart-summary__button--next button button--primary"
          ></button>
        </template>
      </CartSummary>
    </div>

  <ModalSimple
    :showModal="showDeleteCartEntryModal"
    :title="cartEntryToDelete ? `Confirm delete: ${cartEntryToDelete.title}` : ''"
    @confirm="onCartEntryDelete"
    @reject="closeDeleteCartEntryModal"
    @close="closeDeleteCartEntryModal"
  />

  <Modal
    @close="closeDeleteCartModal"
    :showModal="showDeleteCartModal"
    :isLoading="showDeleteCartLoader"
  >
    <template v-slot:header>
      <h3 class="text-h3">Confirm: Remove All Items From Cart</h3>
    </template>
    <template v-slot:footer>
      <div class="modal__actions">
        <button
          @click="closeDeleteCartModal"
          type="button"
          class="button button--outlined"
        >
          Cancel
        </button>
        <button
          @click="handleDeleteCart"
          type="button"
          class="button button--primary"
        >
          Remove
        </button>
      </div>
    </template>
  </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import LoaderMixin from '@/mixins/LoaderMixin'
import UtilityMixin from '@/mixins/UtilityMixin'
import CartStepMixin from '@/mixins/CartStepMixin'
import CartProductsList from '@/components/cart/common/CartProductsList'
import CartSummary from '@/components/cart/common/CartSummary'
import CartProduct from '@/components/cart/common/CartProduct'
import ModalSimple from '@/components/modal/ModalSimple'
import HintBar from '@/components/HintBar'
import Modal from '@/components/modal/Modal'
import CartSaveForLater from '@/components/cart/common/CartSaveForLater'

export default {
  name: 'CartEditStep',
  components: {
    CartProductsList,
    CartSummary,
    CartProduct,
    ModalSimple,
    HintBar,
    Modal,
    CartSaveForLater
  },
  mixins: [LoaderMixin, UtilityMixin, CartStepMixin],
  watch: {
    isProductsPriceRecalculationRequired: {
      immediate: true,
      handler (val) {
        if (val) this.recalculatePoductsPrice()
      }
    }
  },
  computed: {
    ...mapState('cart', ['cartData', 'isProductsPriceRecalculationRequired', 'checkoutStatuses', 'orderStatuses']),
    ...mapGetters('cart', ['cartSize', 'isCartContainsUnavailableProduct']),
    ...mapGetters('user', ['isGuest']),
    potentialPromotions () {
      const combinedPromotions = [
        ...this.cartData.potentialOrderPromotions ? this.cartData.potentialOrderPromotions : [],
        ...this.cartData.potentialProductPromotions ? this.cartData.potentialProductPromotions : []
      ]
      return combinedPromotions?.length
        ? combinedPromotions?.map(el => ({
          code: el.code,
          message: el.description
        }))
        : null
    }
  },
  methods: {
    ...mapActions('cart', ['updateCartEntryQty', 'deleteCartEntry', 'deleteCart', 'recalculatePoductsPrice', 'sendCartStepsHubspotAnalytics']),
    ...mapActions('analytics', [
      'populateRemoveProductsFromCartAnalytics',
      'populateAddProductsToCartAnalytics',
      'populateCheckoutStepsData',
      'populateAddProductToListAnalytics',
      'populateViewReviewCartAnalytics'
    ]),
    ...mapActions('saveForLater', ['moveProductFromCartToSaveForLater']),
    ...mapActions('app', ['doLogin']),
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    onCartEntryUpdate ({ cartEntry, productData, entryNumber, qty, oldQty }) {
      this.withLoader(() =>
        this.updateCartEntryQty({
          entryNumber: entryNumber,
          data: {
            quantity: qty
          }
        }).then(() => {
          const qtyDiff = qty - oldQty
          const isQtyDecreased = !!(qtyDiff < 0)
          if (isQtyDecreased) {
            this.populateRemoveProductsFromCartAnalytics([{
              cartEntry: cartEntry,
              product: productData,
              quantity: Math.abs(qtyDiff)
            }])
          } else {
            this.populateAddProductsToCartAnalytics([{
              sku: productData.sku,
              catalogId: productData.catalogId,
              title: productData.title,
              quantity: Math.abs(qtyDiff),
              brand: cartEntry.extraProperties?.brand,
              category: cartEntry.extraProperties?.category
            }])
          }
        })
      )
    },
    openDeleteCartEntryModal (entry) {
      this.cartEntryToDelete = entry
      this.showDeleteCartEntryModal = true
    },
    closeDeleteCartEntryModal () {
      this.cartEntryToDelete = null
      this.showDeleteCartEntryModal = false
    },
    onCartEntryDelete () {
      this.withLoader(() =>
        this.deleteCartEntry({
          entryNumber: this.cartEntryToDelete?.entryNumber
        })
      )
      this.populateRemoveProductsFromCartAnalytics([{
        cartEntry: this.cartEntryToDelete.cartEntry,
        product: this.cartEntryToDelete.productData,
        quantity: this.cartEntryToDelete.cartEntry.quantity
      }])
      this.closeDeleteCartEntryModal()
    },
    openDeleteCartModal () {
      this.showDeleteCartModal = true
    },
    closeDeleteCartModal () {
      this.showDeleteCartModal = false
    },
    async handleDeleteCart () {
      this.showDeleteCartLoader = true
      const analyticsData = this.cartData.entries.map(cartEntry => ({
        cartEntry: cartEntry,
        product: cartEntry.product,
        quantity: cartEntry.quantity
      }))
      await this.deleteCart()
      this.populateRemoveProductsFromCartAnalytics(analyticsData)
      this.showDeleteCartLoader = false
      this.closeDeleteCartModal()
    },
    moveCartProductToSaveForLater (cartEntry) {
      this.withLoader(() => this.moveProductFromCartToSaveForLater(cartEntry))
    },
    onSaveForLaterClick (cartEntry) {
      this.moveCartProductToSaveForLater(cartEntry)

      this.populateRemoveProductsFromCartAnalytics([{
        cartEntry: cartEntry,
        product: cartEntry.product,
        quantity: cartEntry.quantity
      }])

      this.populateAddProductToListAnalytics({
        cartEntry: cartEntry,
        sku: cartEntry.product.sku,
        catalogId: cartEntry.product.catalogId,
        title: cartEntry.product.title,
        brand: cartEntry.extraProperties?.brand,
        category: cartEntry.extraProperties?.category,
        quantity: cartEntry.quantity,
        position: 1,
        listName: 'Save for later'
      })
    }
  },
  mounted () {
    this.populateCheckoutStepsData({
      step: '1',
      option: ''
    })
    this.populateViewReviewCartAnalytics()
    if (!this.isGuest) {
      this.sendCartStepsHubspotAnalytics({
        orderStage: this.orderStatuses.checkoutPending,
        checkoutStage: this.checkoutStatuses.reviewCart
      })
    }
  },
  data () {
    return {
      showDeleteCartEntryModal: false,
      cartEntryToDelete: null,
      showDeleteCartModal: false,
      showDeleteCartLoader: false,
      hintInsufficientStockError: {
        message: 'An item in your cart is no longer available; please remove from cart to continue'
      }
    }
  }
}
</script>
