<template>
  <div class="cart__content">
    <div class="cart__step">
      <CartGuestCheckoutInterruption
        @emailFormSubmitted='onEmailFormSubmited'
      />
    </div>
    <div class="cart__summary">
      <CartSummary
        :cartData="cartData"
      >
        <template v-slot:flow-control>
          <button
            v-if="currentStep.previous.step"
            @click="onBackStepClick"
            v-html="currentStep.previous.actionText"
            :disabled="!canStepBack"
            class="cart-summary__button cart-summary__button--back button button--outlined"
          ></button>
          <button
            v-if="false"
            @click="onNextStepClick"
            v-html="currentStep.next.actionText"
            :disabled="!canStepForward"
            class="cart-summary__button cart-summary__button--next button button--primary"
          ></button>
        </template>
      </CartSummary>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CartStepMixin from '@/mixins/CartStepMixin'
import CartSummary from '@/components/cart/common/CartSummary'
import CartGuestCheckoutInterruption from '@/components/cart/interruption-step/CartGuestCheckoutInterruption'

export default {
  name: 'CartInterruptionStep',
  components: {
    CartSummary,
    CartGuestCheckoutInterruption
  },
  mixins: [CartStepMixin],
  computed: {
    ...mapState('cart', ['cartData'])
  },
  methods: {
    async onEmailFormSubmited (email) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      if (this.canStepForward) {
        this.incrementStep()
      }
    }
  }
}
</script>
