<template>
  <div class="cart-navigation">
    <div
      v-for="(item, n) in visibleCrumbs"
      :key="item.value"
      class="cart-navigation__item"
    >
      <div class="cart-navigation__inner">
        <span class="cart-navigation__title" v-html="item.navigationTitle"></span>

        <span
          v-if='item.crumbData && item.value === "review-cart"'
          v-html="item.crumbData.title"
          class="cart-navigation__subtitle"
        />

        <AddressItem
          v-if='item.crumbData && item.value === "shipping"'
          :data="item.crumbData"
          class="cart-navigation__subtitle"
        />
        <PaymentCard
          v-if='item.crumbData && item.value === "billing"'
          :data="item.crumbData"
          class="cart-navigation__subtitle"
        />
        <i v-if="isMobile && activeCrumbIndex >= n" class="cart-navigation__progress-icon icon-check-circle"></i>
      </div>
      <div
        v-if="!isMobile"
        class="cart-navigation__progress"
        :class="{
          'cart-navigation__progress--current': isCrumbActive(n),
          'cart-navigation__progress--before-current': isNextCrumbIsActive(n),
          'cart-navigation__progress--completed': isMoreThenOneCrumbToActive(n) || placedOrderData
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import AddressItem from '@/components/AddressItem'
import PaymentCard from '@/components/PaymentCard'

export default {
  name: 'CartNavigation',
  components: {
    AddressItem,
    PaymentCard
  },
  mixins: [ScreenWidthMixin],
  computed: {
    ...mapGetters('cartFlow', ['steps', 'currentStep']),
    ...mapState('cart', ['placedOrderData']),
    visibleCrumbs () {
      return this.steps.filter(el => el.hasBreadcrumb)
    },
    activeCrumbIndex () {
      const currentStepValue = this.currentStep.belongsToCrumbStep || this.currentStep.value
      return this.visibleCrumbs.map(crumb => crumb.value).indexOf(currentStepValue)
    }
  },
  methods: {
    isCrumbActive (n) {
      return this.activeCrumbIndex === n
    },
    isNextCrumbIsActive (n) {
      return this.activeCrumbIndex - n === 1
    },
    isMoreThenOneCrumbToActive (n) {
      return this.activeCrumbIndex - n >= 2
    }
  }
}
</script>
