<template>
  <div class="cart-shipping">
    <HintBar
      v-if="showHintTaxCalculationError"
      ref="hintTaxCalculationError"
      :hintData="hintTaxCalculationError"
      class="cart-shipping__hintbar hint-bar--orange"
    />
    <div class="cart-shipping__top-wrap">
      <div class="cart-shipping__saved-addresses-section" v-if="selectableAddresses">
        <div class="cart-heading">
          <h2 class="cart-heading__title">{{ isGuest ? 'Select Address' : 'Saved Addresses' }}</h2>
          <router-link
            v-if="!isGuest"
            to="/account/address-book"
            class="cart-heading__link"
          >Edit
          </router-link>
        </div>

        <div class="cart-shipping__saved-addresses-list">
          <div
            v-for="selectable in selectableAddresses"
            :key="selectable.id"
            class="cart-shipping__saved-addresses-item"
          >
            <FormRadio
              @change="onSelectShippingAddress"
              :value="cartData.shippingAddress && cartData.shippingAddress.id"
              :field="selectable"
              :disabled="selectable.disabled"
            >
              <template v-slot:label-slot>
                <AddressItem :data="selectable.address" />
              </template>
            </FormRadio>
          </div>
        </div>
      </div>

      <div
        v-if="selectableAddresses"
        @click="showAddressForm = !showAddressForm"
        class="cart-heading cart-heading--clickable"
        :class="{'open': showAddressForm}"
      >
        <h2 class="cart-heading__title">{{ isGuest ? 'Enter Address' : 'Or Add New Address' }}</h2>
        <i class="icon-arrow-down cart-heading__title-icon"></i>
      </div>
      <div class="address-form" v-if="!selectableAddresses || showAddressForm">
        <AddressForm
          @fieldChanged='onFieldChange'
          :form="form"
          :$v="$v"
          :isValidationRun="isValidationRun"
          :formDisabled="isWillCallModeSelected"
          class="address-form__fields"
        >
          <template v-slot:bottom-slot v-if="!isGuest">
            <FormCheckbox
              v-model="form.defaultAddress.value"
              :field="form.defaultAddress"
              :disabled="isWillCallModeSelected"
              class="address-form__make-default-field"
            />
          </template>
        </AddressForm>

        <button
          @click="onAddAddress"
          :disabled="isWillCallModeSelected"
          class="address-form__submit button button--primary"
        >{{ isGuest ? 'Submit' : 'Save Address' }}</button>
      </div>
      <ModalAddressValidation
        @close="closedAddressValidationModal"
        @confirm="setSuggestedShippingAddress"
        :showModal="showModalAddress"
        :suggestedData="suggestedData"
        :enteredData="enteredData"
        :isLoading="showModalAddressValidationLoader"
      />
    </div>

    <div
      v-if="cartData.shippingAddress && deliveryModesData"
      ref="shippingOptions"
      class="cart-shipping__shipping-options-wrap"
    >
      <h2 class="cart-heading__title">Shipping Options</h2>
      <div class="cart-shipping__shipping-options">
        <div
          v-for="mode in deliveryModesData"
          :key="mode.title"
          @click="handleDeliveryModeClick(mode)"
          class="cart-shipping__shipping-option"
          :class="{
            'cart-shipping__shipping-option--selected':
              deliveryModeCode === mode.code && !showDeliveryModesCostLoader,
            'cart-shipping__shipping-option--disabled': checkDeliveryModeDisabled(mode),
            'shimmer-animation': showDeliveryModesCostLoader
          }"
        >
          <span class="cart-shipping__shipping-option-title" v-html="mode.title"></span>
          <span class="cart-shipping__shipping-option-estimated-time" v-html="mode.description"></span>
          <!--isLoading prevents layout from breaking on long lines  -->
          <span
            v-if="!isLoading && mode.promotionDescription"
            class="cart-shipping__shipping-option-promotion"
            v-html="mode.promotionDescription"
          ></span>
          <span
            v-if="checkDeliveryModeDisabled(mode)"
            class="cart-shipping__shipping-option-price">Not Currently Available</span>
          <span
            v-else
            class="cart-shipping__shipping-option-price"
            v-html="deliveryModesCostData && !showDeliveryModesCostLoader
              ? deliveryModesCostData[mode.code]
                ? formatPrice(deliveryModesCostData[mode.code]) : 'Free'
              : 'Pending'"
          ></span>
        </div>
      </div>
    </div>
    <loader v-if="isLoading || showDeliveryModesCostLoader"/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import settings from '@/settings'
import AddressFormMixin from '@/mixins/AddressFormMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import FormatMixin from '@/mixins/FormatMixin'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import FormCheckbox from '@/components/FormCheckbox'
import FormRadio from '@/components/FormRadio'
import AddressItem from '@/components/AddressItem'
import AddressForm from '@/components/account/AddressForm'
import HintBar from '@/components/HintBar'
import ModalAddressValidation from '@/components/modal/ModalAddressValidation'

export default {
  name: 'CartShipping',
  components: {
    FormCheckbox,
    ModalAddressValidation,
    FormRadio,
    AddressForm,
    AddressItem,
    HintBar
  },
  mixins: [LoaderMixin, FormatMixin, AddressFormMixin, NavigationalMixin, ScreenWidthMixin],
  props: ['addressData'],
  watch: {
    'cartData.shippingAddress': {
      async handler (newAddress, oldAddress) {
        if (newAddress?.id && newAddress.id !== oldAddress?.id) {
          // @TODO eliminate race condition
          setTimeout(() => {
            this.withLoader(async () => {
              if (!this.deliveryModesData?.length) await this.setDeliveryModes()
              let deliveryModeCodeToApply = this.deliveryModeCode && !oldAddress?.id ? this.deliveryModeCode : this.deliveryModesData[0].code

              if (this.isEmployee) {
                if (!this.defaultShippingAddresses) {
                  await this.getDefaultShippingAddessesByDeliveryModeCode({
                    code: this.willCallDeliveryModeCode
                  })
                }
                const isWillCallShippingAddressSelected = this.defaultShippingAddresses.some(el => el.id === this.cartData?.shippingAddress?.id)
                if (isWillCallShippingAddressSelected) {
                  deliveryModeCodeToApply = this.deliveryModesData
                    .find(mode => mode.code === this.willCallDeliveryModeCode).code
                }
              }

              this.showDeliveryModesCostLoader = true
              this.setIsDeliveryModesCalculating(true)
              await this.updateDeliveryMode(deliveryModeCodeToApply)
              this.setDeliveryModesCost()
                .catch(this.setDeliveryModesCostData(null))
                .finally(() => {
                  this.setIsDeliveryModesCalculating(false)
                  this.showDeliveryModesCostLoader = false
                })
            })
          }, 100)
        }
      },
      immediate: true
    },
    'cartData.deliveryMode': {
      handler (newMode, oldMode) {
        if (newMode?.code !== oldMode?.code) {
          this.deliveryModeCode = newMode?.code
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('cart', ['deliveryModesData', 'cartData', 'deliveryModesCostData', 'isDeliveryModesCalculating']),
    ...mapState('user', ['defaultShippingAddresses']),
    ...mapGetters('user', ['isGuest', 'isEmployee']),
    selectableAddresses () {
      const combineOwnAddressesWithDefault = () => {
        const ownShippingAddresses = this.addressData?.length
          ? this.addressData?.map(el => this.transformSavedAddress(el, true))
          : []
        const defaultShippingAddresses = this.defaultShippingAddresses?.length
          ? this.defaultShippingAddresses.map(el => this.transformSavedAddress(el)) : []
        return [...ownShippingAddresses, ...defaultShippingAddresses]
      }
      return this.isGuest
        ? this.cartData.shippingAddress ? [this.transformSavedAddress(this.cartData.shippingAddress)] : null
        : this.isWillCallModeSelected && !this.isDeliveryModesCalculating
          ? combineOwnAddressesWithDefault()
          : this.addressData?.map(el => this.transformSavedAddress(el)) || null
    },
    isWillCallModeSelected () {
      return this.cartData.deliveryMode?.code === this.willCallDeliveryModeCode
    },
    willCallDeliveryModeCode () {
      return settings.willCallDeliveryModeCodeByCountry[settings.app.country]
    }
  },
  methods: {
    ...mapActions('cart', [
      'setCartShippingAddressId',
      'setCartShippingAddressData',
      'setCartDeliveryMode',
      'setDeliveryModes',
      'setDeliveryModesCost',
      'deleteShippingAddressFromCart'
    ]),
    ...mapActions('user', ['validateShippingAddress', 'getDefaultShippingAddessesByDeliveryModeCode']),
    ...mapMutations('cart', {
      setDeliveryModesCostData: 'SET_DELIVERY_MODES_COST_DATA',
      setIsDeliveryModesCalculating: 'SET_IS_DELIVERY_MODES_CALCULATING'
    }),
    transformSavedAddress (address, shouldBeDisabled = false) {
      return {
        type: 'radio',
        id: address.id,
        label: address.id,
        value: address.id,
        address: address,
        disabled: !this.isAddressFromCurrentCountry(address) || shouldBeDisabled
      }
    },
    isAddressFromCurrentCountry (address) {
      return address?.country?.isocode === settings.app.country
    },
    async onSelectShippingAddress (val) {
      if (!this.isGuest) {
        return this.withLoader(() => {
          return this.setCartShippingAddressId({ addressId: val.id })
            .then(() => {
              this.anchorTo(this.$refs.shippingOptions)
            })
        })
      }
    },
    onAddAddress () {
      this.withPresendRoutine(() => {
        const formData = {}
        this.pushFormToData(formData)

        return this.sendForm(this.validateShippingAddress, formData)
          .then((res) => {
            if (this.isTwoAddressesEqualForValidation(formData, res)) {
              this.setSuggestedShippingAddress(res)
            } else {
              this.enteredData = formData
              this.suggestedData = res
              this.showModalAddress = true
              return Promise.resolve()
            }
          })
          .catch(() => {
            this.isValidationRun = true
          })
      })
    },
    handleDeliveryModeClick (mode) {
      if (mode.code === this.deliveryModeCode) return
      this.deliveryModeCode = mode?.code
      if (mode?.code === this.willCallDeliveryModeCode) {
        this.setWillCallDeliveryMode()
      } else {
        if (!this.checkDeliveryModeDisabled(mode)) this.updateDeliveryMode(mode.code)
      }
    },
    setWillCallDeliveryMode () {
      return this.withLoader(async () => {
        if (!this.defaultShippingAddresses || !this.defaultShippingAddresses.length) {
          await this.getDefaultShippingAddessesByDeliveryModeCode({
            code: this.willCallDeliveryModeCode
          })
        }
        const defaultShippingAddress = this.defaultShippingAddresses[0]
        if (defaultShippingAddress) await this.setCartShippingAddressId({ addressId: defaultShippingAddress.id })
      })
    },
    updateDeliveryMode (code) {
      const isChangingFromWillCallToAnotherMode = this.cartData?.deliveryMode?.code === this.willCallDeliveryModeCode && code !== this.willCallDeliveryModeCode
      return this.withLoader(async () => {
        if (isChangingFromWillCallToAnotherMode) {
          // To change mode from Will call to another we need to remove default will call delivery address from cart,
          await this.deleteShippingAddressFromCart()
          return this.setShippingAddress()
        }

        return this.setCartDeliveryMode({ code })
          .then(() => {
            this.showHintTaxCalculationError = false
          })
          .catch((err) => {
            if (err?.response?.data[0]?.type === 'AccessDeniedError') return
            this.showHintTaxCalculationError = true
            this.$nextTick(() => {
              this.anchorTo(this.$refs.hintTaxCalculationError.$el)
            })
          })
          // hack to get promotions on delivery modes
          .finally(this.setDeliveryModes)
      })
    },
    checkDeliveryModeDisabled (mode) {
      return this.allowedDeliveryModeCodesWithZeroPrice.includes(mode?.code)
        ? false
        : this.deliveryModesCostData
          ? !this.deliveryModesCostData[mode?.code]
          : false
    },
    closedAddressValidationModal () {
      this.showModalAddress = false
      this.enteredData = {}
      this.suggestedData = {}
    },
    setSuggestedShippingAddress (data) {
      this.showModalAddressValidationLoader = true
      this.setCartShippingAddressData(data)
        .then(() => {
          this.setAddressFormToDefault()
          this.anchorTo(this.$refs.shippingOptions)
        }).catch(() => {
          this.isValidationRun = true
        }).finally(() => {
          this.showModalAddressValidationLoader = false
          this.closedAddressValidationModal()
        })
    },
    async setShippingAddress () {
      const currentCountrySavedAddresses = this.selectableAddresses?.filter(el => this.isAddressFromCurrentCountry(el.address))
      const addressToSet = currentCountrySavedAddresses?.length
        ? currentCountrySavedAddresses[0] : this.selectableAddresses[0]
      await this.onSelectShippingAddress(addressToSet)
    }
  },
  created () {
    this.setDeliveryModesCostData(null)
    if (this.selectableAddresses && !this.cartData.shippingAddress) {
      this.setShippingAddress()
    }
  },
  data () {
    return {
      selectedAddress: null,
      showAddressForm: false,
      showModalAddressValidationLoader: false,
      showModalAddress: false,
      enteredData: {},
      suggestedData: {},
      showHintTaxCalculationError: false,
      showDeliveryModesCostLoader: false,
      hintTaxCalculationError: {
        message: 'Unable to process address; please <a href="/customer-support" target="_blank">Contact Us</a> to resolve'
      },
      form: {
        defaultAddress: {
          type: 'checkbox',
          label: 'Make Primary Address',
          title: 'defaultAddress',
          variant: 'shadow',
          value: false
        }
      },
      deliveryModeCode: null,
      allowedDeliveryModeCodesWithZeroPrice: [
        settings.groundDeliveryModeCodeByCountry[settings.app.country],
        'CPU',
        'CPU_CA'
      ]
    }
  }
}
</script>
